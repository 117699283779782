@mixin until($width) {
     @media (max-width: $width) {
          @content;
     }
}

@mixin from($width) {
     @media (min-width: $width) {
          @content;
     }
}

@mixin size($width, $height: $width) {
     width: $width;
     height: $height;
}

@mixin background-properties($size: cover, $position: center) {
     background-repeat: no-repeat;
     background-size: $size;
     background-position: $position;
}

@mixin flex($display: flex, $justify: center, $align: center) {
     display: $display;
     justify-content: $justify;
     align-items: $align;
}

@mixin main-container {
     display: block;
     width: 100%;
     margin: 0 auto;
     padding: 0 10px;

     @include from(567px) {
          max-width: 540px;
          padding: 0;
     }

     @include from(768px) {
          max-width: 720px;
     }

     @include from(992px) {
          max-width: 960px;
     }

     @include from(1200px) {
          max-width: 1140px;
     }
}

@mixin main-title {
     text-transform: uppercase;
     font-weight: 300;
     text-align: center;
     position: relative;
     margin-bottom: 30px;

     &:after {
          @include size(70px, 2px);
          position: absolute;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 10px;
          background-color: $brand-color-1;
          content: '';
     }
}
