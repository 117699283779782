@font-face {
     font-family: 'Inter';
     src: url('../fonts/Inter-Regular.woff') format('woff'), url('../fonts/Inter-Regular.ttf') format('truetype');
     font-weight: normal;
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: 'Inter';
     src: url('../fonts/Inter-Bold.woff') format('woff'), url('../fonts/Inter-Bold.ttf') format('truetype');
     font-weight: bold;
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: 'Inter';
     src: url('../fonts/Inter-Light.woff') format('woff'), url('../fonts/Inter-Light.ttf') format('truetype');
     font-weight: 300;
     font-style: normal;
     font-display: swap;
}
