$brand-color-white: #fff;
$brand-color-black: #000;

$brand-color-1: #846815;
$brand-color-2: #6464641c;
$brand-color-3: #000000a8;
$brand-color-4: #fafafa;
$brand-color-5: #f7f7f7;
$brand-color-6: #646464;
$brand-color-7: #808080;
$brand-color-8: #0000000d;
$brand-color-9: #5b3b06;
