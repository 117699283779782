@import '../../default/scss/default';
@import '../assets/svg/sassvg/sassvg';

// begin:: header
.header {
     position: sticky;
     top: 0;
     z-index: 9;

     &__logo {
          display: flex;
          align-items: center;
          text-decoration: none;

          img {
               @include size(50px, auto);
               border-radius: 50px;
          }

          span {
               font-size: 18px;
               color: $brand-color-black;
               margin-left: 10px;

               .highlight {
                    color: $brand-color-1;
                    margin-left: -2px;
                    font-weight: 500;
               }
          }
     }

     &__container {
          &:nth-child(1) {
               background-color: $brand-color-1;

               .header__container-inner {
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;

                    @include from(576px) {
                         padding: 10px 0;
                    }
               }
          }

          &:nth-child(2) {
               background-color: $brand-color-white;
               box-shadow: 0 2px 20px $brand-color-2;

               .header__container-inner {
                    box-shadow: 0 2px 20px $brand-color-2;
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;

                    .header__box {
                         align-items: center;
                         display: flex;
                    }

                    @include from(576px) {
                         box-shadow: none;
                         padding: 10px 0;
                    }
               }
          }
     }

     &__container-inner {
          @include main-container;
     }

     &__row {
          width: 100%;
     }

     &__box {
          @include from(576px) {
               display: flex;
          }
     }

     &__email,
     &__working-time,
     &__phone {
          color: $brand-color-white;
          font-size: 10px;
          padding-left: 25px;
          position: relative;

          &:after {
               @include size(17px);
               content: '';
               left: 0;
               position: absolute;
               top: 50%;
               transform: translateY(-50%);

               @include until(576px) {
                    @include size(15px);
               }
          }

          @include until(576px) {
               display: block;
          }

          @include from(768px) {
               font-size: 12px;
          }
     }

     &__email {
          &:after {
               @include sassvg('envelope', $brand-color-white);
               @include background-properties(contain, center);
          }

          @include from(576px) {
               margin-right: 30px;
          }
     }

     &__working-time {
          display: none;

          &:after {
               @include sassvg('clock', $brand-color-white);
               @include background-properties(contain, center);
          }

          @include from(576px) {
               display: block;
          }
     }

     &__phone {
          &:after {
               @include sassvg('phone', $brand-color-white);
               @include background-properties(contain, center);
          }
     }

     &__list {
          display: none;

          @include from(992px) {
               align-items: center;
               display: flex;
               list-style: none;
               margin: 0;
               padding: 0;
          }
     }

     &__nav {
          display: flex;
     }

     &__item {
          &:not(&:last-of-type) {
               margin-right: 10px;
          }

          &:last-of-type {
               border: 1px solid $brand-color-1;
               border-radius: 3px;
               padding: 5px 10px;
               transition: 0.25s ease-in-out;

               &:hover {
                    background-color: $brand-color-1;

                    .header__link {
                         color: $brand-color-white;

                         &:after {
                              display: none;
                         }
                    }
               }

               a {
                    color: $brand-color-1;
                    text-transform: uppercase;
               }
          }
     }

     &__link {
          color: $brand-color-black;
          padding-bottom: 5px;
          position: relative;
          text-decoration: none;

          &:after {
               @include size(0, 2px);
               background-color: $brand-color-1;
               border-radius: 10px;
               bottom: 0;
               content: '';
               left: 0;
               position: absolute;
               transition: width 0.3s;
          }

          &:hover {
               &:after {
                    transition: width 0.3s;
                    width: 100%;
               }
          }

          &--active {
               color: $brand-color-1;

               &:after {
                    width: 100%;
               }
          }
     }

     &__item {
          &:last-child {
               a {
                    &:after {
                         content: '';
                         width: 0;
                    }
               }
          }
     }

     &__toggle {
          @include size(25px, 16px);
          background: transparent;
          border: 0;
          cursor: pointer;
          outline: none;
          padding: 2px 0;
          position: relative;

          &:before,
          &:after {
               background-color: $brand-color-1;
               content: '';
               height: 2px;
               left: 0;
               position: absolute;
               right: 0;
               transition: 0.3s ease-in-out;
          }

          &:before {
               top: 0;
          }

          &:after {
               bottom: 0;
          }

          span {
               border-top: 2px solid $brand-color-1;
               display: block;
               height: 0;
               overflow: hidden;
          }

          &--active {
               span {
                    opacity: 0;
               }

               &:before {
                    top: 7px;
                    transform: rotate(45deg);
               }

               &:after {
                    bottom: 7px;
                    transform: rotate(-45deg);
               }
          }

          @include from(992px) {
               display: none;
          }
     }
}
// end:: header

// begin:: hero
.hero {
     height: 200px;

     &__slides,
     &__slide {
          @include size(100%);
     }

     &__slide {
          img {
               @include size(100%);
               object-fit: cover;
          }
     }

     .glide {
          position: relative;

          &__arrows {
               pointer-events: none;
          }

          &__arrow {
               @include size(40px, 50px);
               @include sassvg('chevron-right', $brand-color-white);
               background-color: transparent;
               pointer-events: all;
               border: 0;
               cursor: pointer;

               &--left {
                    transform: rotate(180deg);

                    &:hover {
                         @include sassvg('chevron-right', $brand-color-1);
                    }
               }

               &--right {
                    &:hover {
                         @include sassvg('chevron-right', $brand-color-1);
                    }
               }
          }
     }

     &__title {
          color: $brand-color-white;
          font-size: 32px;
          position: absolute;
          right: 15%;
          top: 82%;
          display: none;

          &:after {
               bottom: 41px;
               color: $brand-color-1;
               content: 'COOLIC';
               font-size: 25px;
               font-weight: bold;
               position: absolute;
               right: -62px;
               transform: rotate(270deg);
          }

          @include from(768px) {
               display: block;
               font-size: 40px;

               &:after {
                    bottom: 49px;
                    font-size: 32px;
                    right: -103px;
               }
          }
     }

     &__scroll-down {
          @include flex;
          left: 50%;
          position: absolute;
          top: 76%;
          transform: translateX(-50%) scale(0.6);

          @include from(1000px) {
               top: 90%;
               transform: translateX(-50%) scale(0.8);
          }
     }

     .chevron {
          animation: move-chevron 3s ease-out infinite;
          height: 0.6rem * 0.6;
          opacity: 0;
          position: absolute;
          transform: scale(0.3);
          width: 0.6rem * 3.5;

          &:nth-child(1) {
               animation: move-chevron 3s ease-out 1s infinite;
          }

          &:nth-child(2) {
               animation: move-chevron 3s ease-out 2s infinite;
          }

          &:before,
          &:after {
               @include size(50%, 100%);
               background: $brand-color-white;
               content: '';
               position: absolute;
               top: 0;
          }

          &:before {
               left: 0;
               transform: skewY(30deg);
          }

          &:after {
               right: 0;
               transform: skewY(-30deg);
               width: 50%;
          }
     }

     @keyframes move-chevron {
          25% {
               opacity: 1;
          }

          33.3% {
               opacity: 1;
               transform: translateY(0.6rem * 3.8);
          }

          66.6% {
               opacity: 1;
               transform: translateY(0.6rem * 5.2);
          }

          100% {
               opacity: 0;
               transform: translateY(0.6rem * 8) scale(0.5);
          }
     }

     @include from(768px) {
          height: 300px;
     }

     @include from(1000px) {
          height: calc(100vh - 34px - 60px);
     }
}
// end:: hero
