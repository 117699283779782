@import 'vars';
@import 'fonts';
@import 'base/mixins';
@import 'glide';

html {
     scroll-behavior: smooth;
}

* {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
     font-family: 'Inter', sans-serif;
     font-weight: 300;

     body {
          overflow-x: hidden;
          margin-right: 0 !important;

          &.fixed {
               overflow: hidden;
          }

          &::-webkit-scrollbar {
               display: none;
               width: 0;
               height: 0;
          }

          &::-webkit-scrollbar-track {
               display: none;
               width: 0;
               height: 0;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
               display: none;
               width: 0;
               height: 0;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
               display: none;
               width: 0;
               height: 0;
          }

          img {
               opacity: 0;

               &:not(&.loading) {
                    opacity: 1;
               }
          }
     }
}
